import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './custom.css';
import Home from './Pages/Home/Home';
import Game from './Components/Game/Game';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Privacy from './Pages/Privacy/Privacy';
import GameIframe from './Components/GameIframe/GameIframe';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import Mobile from './Components/Mobile/Mobile';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';

function App() {
  const [cookies, setCookie] = useCookies(["user"]);
  console.log(isMobile);
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={isMobile ? <Mobile name="mobile" /> : <Home name="Home" />} />
        <Route path='/home' element={<Home />}></Route>
        <Route path='/game' element={<Game />}>
          <Route path=':gameId' element={<Game />} />
        </Route>
        <Route path='/aboutus' element={<About name="aboutus" />} />
        <Route path='/contactus' element={<Contact name="contactus" />} />
        <Route path='/privacy-policy' element={<Privacy name="privacy-policy" />} />
        <Route path='/games' element={<GameIframe name="Games" />}>
          <Route path=':gameId' element={<GameIframe name="Games" />} />
        </Route>
        <Route path='/mobile' element={<Mobile name="mobile" />} />

        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
